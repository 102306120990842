.about__container{
height: 100%;
width :100%;
    position:absolute;
    /* background-color: aqua; */
    align-items: center;
    margin-top: 4%;
    box-sizing: border-box;
}
/* .about__container h1{
    font-size: 2em;
    color: rgb(0, 128, 109);
 
   text-align: center;

} */
/* p{
    font-size: .9em;
    color: rgb(0, 128, 109);
  text-justify: auto;
  font-family:'Times New Roman', Times, serif; 
} */
/* .container-flex{
    display: flex;
    justify-content: space-around;
    background-color: rgb(226, 43, 43);
} */
.inner_content{
   
    display: flex;
    justify-content: space-evenly;
    /* background-color: rgb(226, 43, 43); */
    margin-top: 2%;
}
.text_container{
    width: 50%;
    height: auto;
    /* background-color: blueviolet; */
    justify-content: center;
    padding: 1em;
    padding-left: 1em;
}
.text_container img{
    width: 90%;
    height: auto;
    display: block;
    margin: 0 3 ;
    margin-left: 5%;

}

.img_container{
   
    width: 50%;
    height: auto;
    /* background-color: rgb(160, 147, 145); */
    justify-content: center;
    padding: 1em;
    padding-left: 1em;
   
    

}
.img_container img{
    width: 80%;
    height: auto;
    display: block;
    margin: 0 auto;
    /* object-fit: cover; */
     
 
 }
/* .about_img{
    width: 550px;
    height: auto;
  justify-content: space-around;
    
} */
@media only screen and (max-width: 900px) {
    /* For mobile phones: */
    html{
        font-size: 14px;
    }
}
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    html{
        font-size: 14px;
    }
    .about__container{
        flex-direction: column;
        margin-top: 1em;

    }
    
}

@media only screen and (max-width: 568px) {
    /* For mobile phones: */
    html{
        font-size: 11px;
    }
    .inner_content{

        flex-direction:row;
        display:grid;

    }
    .img_container{
   
        width: 100%;
        padding:3em;
       
      
      
        
    
}
.img_container img{
    width: 350px;
    height:auto;
}
.text_container{
    width: 100%;
    padding: 1em;
    /* background-color: aqua; */

} 
.text_container img{
    width:350px;
    height:auto;
}

/* .text_container h1{
   
   font-size: 2em;
} */
} 
/* @media only screen and (max-width: 768px) {
    
    [class*="col-"] {
      width: 100%;
    }
  }
  @media only screen and (max-width: 568px) {
    
    [class*="col-"] {
      width: 100%;
    }
} */
