.card_container{
  /* background-image:-moz-linear-gradient(3 4 5); */
    height:180px;
    width:180px;
    background-color:rgb(255, 255, 255);
    backdrop-filter:blur(8px);
    border-top: 1px solid rgba(3, 1, 0, 0.4);
    border-left: 1px solid rgba(2, 1, 0, 0.3);
    margin-top: 5%;
    border-radius: 20px;
    box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.25);
    margin-left:60% ;
    margin-right: auto;
    text-align: center;
    justify-content: center; 
    border-bottom-right-radius: 50px;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
  }
  @media only screen and (max-width: 568px) {
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
    .card_container{
      height: 110px;
      width: 110px;
      background-color:rgb(255, 255, 255);
    }
}