
.data__content{
  padding: 10px 20;
   color: beige;
    display: flex;
   column-gap: 20px;
   ;
}
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
  }
  @media only screen and (max-width: 568px) {
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
}