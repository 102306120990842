.testimonial_container{
    height:100%;
    width:100%;
    position:relative;
    display: flex;
    box-sizing: border-box;
    margin-top: 8%;
    text-align: center;
    justify-content: center;
    
}
.testimonialinner_container{
    /* background-color: black; */
    height:100%;
    width:100%;
    
}
.testimonial_container h1{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 40px;
    color: rgb(255, 0, 0);
    font-weight: 200;
    display:flex;
    text-align: center;
    justify-content: center;

}
/* .outer__Container{
    height:90%;
    width:96%;
    margin-left: 2%;
    margin-right: auto;
    position: absolute;
    text-align: center;
    justify-content: center; 
    background-color: aqua; 
} */
.container__flex{
    height: 90%;
    width: 100%;
    position: absolute;
    align-items: center;
    justify-content: center; 
    /* background-color: rgb(255, 255, 255);  */
    display: flex;
}
/* .testimonialcard__container{
    height:130px;
    width:280px;
    background-color:rgb(29, 133, 70);
    cursor: pointer;
    position:absolute;
    margin-top: 5%;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    margin-left:60% ;
    margin-right: auto;
    text-align: center;
    justify-content: center; 
    
} */
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
  }
  @media only screen and (max-width: 568px) {
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
}