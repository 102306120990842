  .footer {
    background-color: rgb(255, 255, 255);
      color: rgb(0, 128, 109);
      padding: 1rem;
      /* position: fixed; */
      width: 100%;
      height:120px;
      padding-left: 0;
      margin-top: 0%;
      margin-bottom: 0%;
   
 
    }
    .container{
        align-items: center;
        justify-content: center;

    }
    .text-center{
     
        text-align: center;
    }
    .text-center p{   
        font-weight:100;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }