.expert_container{
    height:100%;
    width:100%;
    position:absolute;
    box-sizing: border-box;
    display: contents;
    margin-top: 8%;
   
    justify-content: center;
    background-color: brown;
   }
   .expertinner_container{
   width: 100%;
   height: 90%;
   position: relative; 
   
    justify-content: center;
    align-items: center;
    overflow:hidden;
/* background-color: aquamarine; */
   }
   .expert_container h1{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 40px;
    color:rgb(0, 128, 109);
    font-weight: 200;margin-top: 7%;
    /* display:flex; */
    text-align: center;
    justify-content: center;
   }
   .Ecard__container{
    /* background-color: rgb(225, 241, 236); */
    margin-left: 15%;
    margin-right: 10%;
    margin-top: 5%;
    grid-template-columns: auto;
    /* background-color: blue; */
   }


    .Ecard_container .image__container :hover {
    transform: scale(1.03);
  }

   @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
  }
  @media only screen and (max-width: 400px) {
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
    .Ecard__container{
      display: grid;
      flex-direction: row;






      grid-column: 150px 150px;
    }
    
}
   