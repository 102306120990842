.container-slider {
    max-width: 100%;
    height: 50rem;
    margin: 80px auto 0px;
    position:relative;
    overflow: hidden;

    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

 box-sizing: border-box;
  }
  .news-container{
    height:180px;
    width:180px;
    background-color:rgb(255, 255, 255);
    backdrop-filter:blur(8px);
    border-top: 1px solid rgba(3, 1, 0, 0.4);
    border-left: 1px solid rgba(2, 1, 0, 0.3);
    margin-top: 5%;
    border-radius: 20px;
    box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.25);
    margin-left:60% ;
    margin-right: auto;
    text-align: center;
    justify-content: center; 
    border-bottom-right-radius: 50px;
  }
  
  /* .images{
    max-width: 100%;
    height: 40rem;
    width:auto;
    margin: 80px auto 0px;
    position:relative;
   
  }  */
  .swiper {
    width: 100%;
    height: 100vh;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
  
  /* @media screen and (max-width: 600px){
    .container-slider {
      margin: 100px 10px 0;
    }
  } */
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
  }
  @media only screen and (max-width: 568px) {
    /* For mobile phones: */
    [class*="col-"] {
    width: 100%;

}

.container-slider {
  width: 50%;
  height: auto;
  }
  .swiper{
   
    width:55vh;
    height: 40vh;
    margin-top: 30px;
  }
}
  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
  }
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
   
  }
  .active-anim {
    opacity: 1;
  }
  
  .btn-slide {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #f1f1f1;
    border: 1px solid rgba(34, 34, 34, 0.287);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .btn-slide img {
    width: 25px;
    height: 25px;
    pointer-events: none;
  }
  .prev {
    top: 50%;
    left: 20px;
    transform: translateY(-60%);
  }
  .next {
    top: 50%;
    right: 20px;
    transform: translateY(-60%);
  }
  
  
  .container-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  .dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    border: 3px solid #f1f1f1;
    margin: 0 5px;
    background: #f1f1f1;
  }
  .dot.active {
    background: rgb(32, 32, 32);
  }
 
  
    .h1content_text{
    position:absolute;
    display:flex;
    backface-visibility: visible;
    top:450px;
    justify-content:space-around;
    font-size: 60px;
    left:20%;
    right: 20%;
    color: rgb(15, 124, 0);
    /* text-shadow: 3px 3px 3px rgb(255, 255, 255); */

  }
  .h2content_text{
    position:absolute;
    display:flex;
    backface-visibility: visible;
    top:530px;
    left:20%;
    right: 20%;
    font-size: 20px;
    justify-content:space-around;
    color:rgb(0, 128, 109)
    /* text-shadow:3px 3px 3px rgb(255, 255, 255); */
    
  }

  