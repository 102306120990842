* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section{
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  height: 100vh;
  margin-top: 0%;
  background-image: url('./components/Images/abstract.jpg');

 
 
}
.secondary_section{
  width: 100%;
  margin-top: 0;
  background-color: rgb(224, 226, 240);
  display:flexbox;
  background-image: url('./components/Images/26841.jpg');
  backface-visibility: visible;
  z-index: 20000;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}
@media only screen and (max-width: 568px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}




