.contact__container{
    height:100%;
    width:100%;
    position:absolute;
    margin-top: 8%;
 
   
    
}
.contact__container h1{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 40px;
    color: rgb(255, 255, 255);
    font-weight: 200;
      /* text-align: center;
    justify-content: center; */
    text-align: center;
   }
   .contact__content{
    width:95%;
    height: 75%;
    display: flex;
    justify-content: space-around;
    /* background-color: rgb(69, 0, 0); */
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 1%; */
    /* border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);  */
  
   
}
.address__content{
    width:50%;
    height: 65%;
    /* display: flex; */
    justify-content: space-around;
    background-color: rgb(255, 255, 255);
    margin-top: 5%;
    margin-left: 1%;
    margin-right: auto;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
   
}
.address__content h2{
    margin-left: 20px;
}
.address__content p{
    margin-left: 20px;
}
.map__content{
    width:50%;
    height: 50%;
    /* display: flex; */
    /* justify-content: space-around;
    background-color: rgb(255, 255, 255); */
    margin-top: 5%;
    margin-right: 10%;
    margin-left: 10%;
    /* border-radius: 14px; */
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); */
}
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
  }
  @media only screen and (max-width: 568px) {
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
}