.department_container{

    height: 100%;
    width:100%;
    position:relative;
    box-sizing: border-box;
    margin-top: 8%;
    /* text-align: center;
    justify-content: center;
    overflow: hidden; */
    /* background-color: brown; */
 

}
.departmentinner_container h1{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 40px;
    color: rgb(6, 38, 53);
    font-weight: 200;
    display:flex;
    text-align: center;
    justify-content: center;
  

}
.cards_details{
    display:grid;
    /* text-align: center; */
    /* justify-content: center; */
    grid-template-columns: 200px 200px 200px 200px 200px;
    /* grid-template-rows:200px; */
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 1%;
    gap:10px;

    overflow:visible;
    position:relative;
    /* background-color: aqua; */

   
 
}
.cards_container .cards_details :hover {
  transform: scale(1.02);
}
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
  }
  @media only screen and (max-width: 568px) {
    /* For mobile phones: */
  
    [class*="col-"] {
      width: 100%;
    }
    .department_container{
      display: grid;
      flex-direction:row;
    
    }

    .cards_details{
      
      grid-template-columns: 200px 200px ;
      /* margin-right:10% ; */
      padding: 0.5em;
     
      justify-content: center;
      font-size: 10px;
      gap:8px;
    }
    .cards_details img{
      width:70px;
      height: auto;
    }
    .cards_container .cards_details :hover {
      transform: scale(1.03);
    }

}
