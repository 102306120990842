:root{
  --mainColor:rgb(54, 61, 117);}
.header {
  background-color: var(--mainColor);
    color: #fff;
    padding: 1rem;
    position: fixed;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
    z-index: 100000;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    height: 35px;
    cursor: pointer;
    align-items: center;

  }
  
  .navContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
  }
  
  .navContainer h1 {
    font-size: 1.5rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    margin-top: -15%;
  
  }
  
  .navContainer nav {
    display: flex;
    gap: 2.5rem;
  }
  
  .navContainer button {
    position: relative;
    font-size: 1.1rem;
    cursor: pointer;
    color: #eaeaea;
    background: none;
    border: none;
    top: -25px;
  }
  
  .navContainer button:not(.activeClass)::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: #eaeaea;
    left: 0;
    bottom: -5px;
    transform: scale(0, 1);
    transition: transform 0.25s ease;
    transform-origin: 0 100%;
  }
  
  .navContainer button:not(.activeClass):hover::before {
    transform: scale(1, 1);
  }
  
  .navContainer button.activeClass::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background: #eaeaea;
    bottom: -5px;
    left: 0;
  }
  .logoContainer{

    width: 100 150px;
    padding: 0 0;
    display: flex;
    align-items: center;
    
    bottom: 20px;
}


.logo{
    width: 100px;
    padding:0 0;
  box-sizing: border-box;
    }  


    @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      [class*="col-"] {
        width: 100%;
      }
    }
    @media only screen and (max-width: 568px) {
      /* For mobile phones: */
      [class*="col-"] {
        width: 100%;
      }
     
      .navContainer nav {
        display:none;
        
      }
      .logo{
        width: 130px;
        padding:0 0;
      box-sizing: border-box;
    margin-bottom: -25px;
   
        }  
        .navContainer h1 {
          font-size: 2.5rem;
        }

  }