.gallery_container{
    height:100%;
    width:100%;
    position:relative;
    display: flex;
    box-sizing: border-box;
    margin-top: 8%;
    text-align: center;
    justify-content: center;
    }
    .galleryinner_container h1{
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 40px;
        color: rgb(0, 128, 109);
        font-weight: 200;
        /* display:flex; */
        text-align: center;
        justify-content: center;
       


    }
    .space {
      margin: 0 auto;

    }
    /* img {
      float: left;
      width:  100px;
      height: 100px;
      object-fit: cover;
  } */
   /* for converting image in same height and width */
    
    .galleryWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      align-items: center;
      justify-content: center;
      max-width: 1580px;
      margin: 0 auto;

    }
    
    .galleryWrap .single {
      width:180px;
      cursor: pointer;
      
    }
    
    .galleryWrap .single img {
     
      float: left;
      object-fit: cover;
      width:  160px;
      height: 160px;
    }
    
    .galleryWrap .single img:hover {
      transform: scale(1.02);
    }
    
    .sliderWrap {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background-color: rgba(0, 0, 0, 0.8);
      display:block;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    
    .sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
      position: fixed;
      cursor: pointer;
      opacity: 0.6;
      color: #fff;
      z-index: 9999;
    }
    
    .btnNext:hover, .btnPrev:hover, .btnClose:hover {
      opacity: 1;
    }
    
    .sliderWrap .btnClose {
      top: 10%;
      right: 40px;
    }
    
    .sliderWrap .btnPrev {
      top: 50%;
      transform: translateY(-50%);
      left: 40px;
    }
    
    .sliderWrap .btnNext {
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
    }
    
    .fullScreenImage {
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .fullScreenImage img {
      max-width: 100%;
      max-height: 100%;
      pointer-events: none;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */  
    }
    @media only screen and (max-width: 768px) {
        /* For mobile phones: */
        [class*="col-"] {
          width: 100%;
        }
      }
      @media only screen and (max-width: 568px) {
        /* For mobile phones: */
        [class*="col-"] {
          width: 100%;
        }
    }
