
.Ecard_container{
    height:330px;
    width:280px;
    background-color:rgb(9, 145, 27);
    backdrop-filter:blur(8px);
    border-top: 1px solid rgba(3, 1, 0, 0.4);
    border-left: 1px solid rgba(2, 1, 0, 0.3);
    cursor: pointer;
    position:relative;
    margin-top: 5%;
    border-radius: 14px;
    box-shadow: 1px 5px 5px rgba(0,0 , 0, 0.189);
    text-align: center;
    justify-content: center;  
    align-items: center;
}


.image__container{
  width: 280px;
  height:260px;
    /* justify-content: center;  */
    box-sizing: border-box;
    /* border-radius: 144px; */
    box-shadow: 1px 5px 5px rgba(14, 4, 51, 0.25);
    background-color: rgb(255, 255, 255);
    align-items: center;


}
.imgcontent{
width: 260px;
height:240px;
 box-sizing: border-box;
 /* border-radius: 144px; */
 justify-content: center;
 align-items: center;
 box-shadow: 10px 5px 5px rgba(14, 4, 51, 0.25);
}
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
  }
  @media only screen and (max-width: 568px) {
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
    
}